@import '~antd/dist/reset.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-page {
  position: relative;
  height: 100vh;
}
.login-page .login-box {
  background: #fff;
  border-radius: 5px;
  max-width: 400px;
  box-shadow: 0 12px 40px rgba(0,0,0,.12);
  padding: 60px 60px 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-box .header {
  text-align: center;
}
.login-box img {
  margin-bottom: 25px;
}


.wrapper .header .logo {
  float: left;
  width: 239px;
  height: 64px;
  margin: 0;
  background: #001529;
  color: #fff;
  text-align: center;
  line-height: 56px;
  border-right: 1px solid #5a5a5a;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-header {
  padding: 0;
  padding-inline: 0 !important;
  line-height: 56px;
  height: 60px;
}
.ant-layout-header img{
  height: 50px;
}

.ant-layout-header .logout{
  float: right;
  margin-right: 20px;
}

.ant-layout-header a{
  color: #fff;
}

.ant-page-header {
  padding: 0 !important;
}

.ant-page-header, .ant-page-header-back, .ant-page-header-back-button, .anticon {
  cursor: pointer;
}

.ant-page-header-back-button {
  line-height: 1 !important;
}
.ant-page-header-back-button .anticon svg {
  width: 18px !important;
  height: 18px !important;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important;
}
.swal2-styled.swal2-confirm {
  background-color: #1890ff !important;
  border-radius: 2px !important;
  padding: .3em 1.1em !important;
}
.swal2-styled.swal2-cancel {
  background-color: #ff4d4f !important;
  border-radius: 2px !important;
  padding: .3em 1.1em !important;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}
.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-hover tbody tr:hover {
  background-color: rgba(0,0,0,.075);
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.small-divider {
  margin: 10px 0;
}

.small-divider {
  margin: 15px 0;
}

.media {
  cursor: pointer;
  display: flex;
}

.media .options{
  display: none;
  line-height: 1;
}
.media:hover .options{
  display: block;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  box-sizing: border-box;
  margin: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: #000000d9;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-btn {
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
}
.ant-btn-primary {
  color: #fff;
  background-color: #4338ca;
  box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
}

.ant-input-group-compact {
  display: flex !important;
}
.ant-input-group-compact .ant-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.icon-picker-list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding-left: 0;
  padding-right: 5px;
  max-height:200px;
  overflow-y:scroll;
  justify-content:space-between;
}

.icon-picker-list li {
  width: 25%;
  margin-bottom:5px;
}

.icon-picker-list .af-box {
  background-color: #f9f9f9;
  border: 1px solid #fff;
  color: black;
  display: block;
  flex: 1 1 auto;
  font-size: 12px;
  line-height: 1.4;
  min-height: 100px;
  padding: 10px;
  text-align: center;
  user-select: none;
}

.icon-picker-list .af-box:hover,
.icon-picker-list .af-box.active{
  background-color: #009E49;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.icon-picker-list .fa,
.icon-picker-list .fas,
.icon-picker-list .far,
.icon-picker-list .fab {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.icon-picker-list .name-class {
  display: block;
  text-align: center;
  word-wrap: break-word;
}
.card-spec .ant-card-body {
  padding-top: 8px !important;
}
.service-box {
  text-align: center;
  padding: 20px 5px;
}
.service-box i {
  font-size: 48px;
  margin-bottom: 15px;
}
.service-box span {
  display: block;
  font-size: 20px;
  line-height: 26px;
}
.stat-card {
  background: #fff;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  transition: all 0.2s;
}
.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
}
.stat-card .stat-card__content h2 {
  font-size: 2rem;
}
.stat-card .stat-card__content h2 i {
  font-size: 26px;
  font-weight: normal;
}
.stat-card .stat-card__icon-circle {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background: rgb(19, 36, 90, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-card .stat-card__icon .stat-card__icon-circle i {
  font-size: 30px;
  color: #13243c;
}
.anticon {
  display: inline-flex !important;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 10px!important;
  border-top-right-radius: 10px!important;
}
.ql-container.ql-snow {
  border-bottom-left-radius: 10px!important;
  border-bottom-right-radius: 10px!important;
}
.ant-collapse-header-text {
  font-weight: bold;
}
@media print {
  header, aside, footer {
    display: none;
  }
}
